<template>
  <div>
    <v-container
      style="
        max-width: 800px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      "
    >
      <v-row style="margin-top: 5px">
        <img
          src="https://1410.nyc3.digitaloceanspaces.com/14-10-images/header_index.png"
          alt="14-10 Business Logo"
          style="margin: 0px auto; width: 190px; padding: 0px"
        />
      </v-row>
    </v-container>

    <div class="pin_container">
      <div class="card card_small" id="card1"></div>
      <div class="card card_medium" id="card2"></div>
      <div class="card card_large" id="card3"></div>
      <div class="card card_small" id="card4"></div>
      <div class="card card_medium" id="card5"></div>
      <div class="card card_large" id="card6"></div>
      <div class="card card_small" id="card7"></div>
      <div class="card card_medium" id="card8"></div>
      <div class="card card_large" id="card9"></div>
      <div class="card card_small" id="card10"></div>
      <div class="card card_medium" id="card11"></div>
      <div class="card card_large" id="card12"></div>
      <div class="card card_small" id="card13"></div>
      <div class="card card_medium" id="card14"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {},
};
</script>

<style>
#card1 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/engagement.jpg")
    no-repeat center center / cover;
}

#card2 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/dog-pool.jpg")
    no-repeat center center / cover;
}

#card3 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/baby.jpg")
    no-repeat center center / cover;
}

#card4 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/couple.jpg")
    no-repeat center center / cover;
}

#card5 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/scout.jpg")
    no-repeat center center / cover;
}

#card6 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/headshot.jpg")
    no-repeat center center / cover;
}

#card7 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/family.jpg")
    no-repeat center center / cover;
}

#card8 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/ny-1.jpg")
    no-repeat center center / cover;
}

#card9 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/booker.jpg")
    no-repeat center center / cover;
}

#card10 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/apartment.jpg")
    no-repeat center center / cover;
}

#card11 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/ny-2.jpg")
    no-repeat center center / cover;
}

#card12 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/booker-2.jpg")
    no-repeat center center / cover;
}

#card13 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/music-2.jpg")
    no-repeat center center / cover;
}

#card14 {
  background: url("https://1410.nyc3.digitaloceanspaces.com/14-10-images/engagement-2.jpg")
    no-repeat center center / cover;
}

:root {
  --card_width: 350px;
  --card_border_radius: 16px;
  --row_increment: 10px;
  --card_small: 26;
  --card_medium: 33;
  --card_large: 45;
}

.pin_container {
  margin: 0;
  padding: 0;
  width: 95vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--card_width));
  grid-auto-rows: var(--row_increment);
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

@media (max-width: 800px) {
  .pin_container {
    width: 100vw;
  }
}

.card {
  padding: 0px;
  margin: 15px 10px;
  background-color: red;
  border-radius: var(--card_border_radius);
  filter: drop-shadow(0px 10px 4px #c4c4c4);
}

.card_small {
  grid-row-end: span var(--card_small);
}

.card_medium {
  grid-row-end: span var(--card_medium);
}

.card_large {
  grid-row-end: span var(--card_large);
}
</style>
